<template>
    <div
        id="rphq-embed-main"
        class="overflow-hidden p-1"
    >
        <prime-button
            v-if="showBackButton"
            class="-mb-5 !px-2 !py-1"
            icon="pi pi-chevron-left"
            label="Back"
            @click="router.go(-1)"
        />

        <slot />

        <powered-by-footer class="mt-6" />
    </div>
</template>

<script setup lang="ts">
import { provideViewer } from '~/composables/use-login'
import { provideActiveFacilityFromSlug } from '~/composables/use-active-facility'
import { computed, onBeforeUnmount, provide, ref } from 'vue'
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { createError, useRoute } from '#app'
import { useHead } from 'unhead'
import { provideEmbedParams } from '~/composables/use-embed-params'
import { useRouter } from 'vue-router'
import PrimeButton from 'primevue/button'
import PoweredByFooter from '~/components/PoweredByFooter.vue'

useHead({
    script: {
        type: 'text/javascript',
        tagPosition: 'bodyOpen',
        innerHTML:
            'setInterval(() => {\n' +
            "  const rphqEmbedMain = document.getElementById('rphq-embed-main');\n" +
            '  if (rphqEmbedMain !== null) {\n' +
            "    parent.postMessage('RPHQ::1::' + JSON.stringify({" +
            "      key: 'iframe-resize',\n" +
            '      height: rphqEmbedMain.scrollHeight\n' +
            "    }), '*');\n" +
            '  }\n' +
            '}, 50);'
    }
})

provideEmbedParams()
const router = useRouter()
const route = useRoute()

const showBackButton = computed(() => route.query.origin !== route.params.slug)

const activeFacility = await provideActiveFacilityFromSlug()
await provideViewer()

const unmount = ref(false)
const headStyle = computed(() => {
    if (unmount.value) {
        return []
    }

    return activeFacility.value.branding.styleString
})

useHead({
    style: headStyle
})

onBeforeUnmount(() => {
    // Clear styles when leaving the component
    unmount.value = true
})

const query = useRpQuery({ orgLevel: true })
const { data, isLoading, suspense } = useQuery({
    queryKey: ['EmbedLayoutQuery', computed(() => activeFacility.value.id)],
    placeholderData: previousData => previousData,
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query EmbedLayoutQuery($facilityId: ID!) {
                    facility(id: $facilityId) {
                        id
                        storefront {
                            treeNavigation
                            collectMoney
                            profileAllowed
                            idleTimeout
                            idleTimeoutMessage
                            config(language: ENGLISH)
                        }
                    }
                }
            `),
            {
                facilityId: activeFacility.value.id
            }
        )
})

await suspense()

const storefrontPrefix = computed(() => `/${activeFacility.value.slug}`)
const catalog = computed(() => {
    if (
        data.value?.facility?.storefront === undefined ||
        data.value?.facility?.storefront === null
    ) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }

    return data.value.facility.storefront.config
})
provide('CATALOG', catalog)
provide('STOREFRONT_PREFIX', storefrontPrefix)
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

#__nuxt {
    font-size: 13px;
}
</style>
